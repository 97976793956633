import { create } from 'zustand';

interface Video {
  playbackId: string;
}

interface VideoStore {
  videos: Video[];
  isLoading: boolean;
  error: string | null;
  fetchVideos: () => Promise<void>;
}

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:3000';

export const useVideoStore = create<VideoStore>((set) => ({
  videos: [],
  isLoading: false,
  error: null,
  fetchVideos: async () => {
    try {
      set({ isLoading: true, error: null });
      console.log('Iniciando fetch dos vídeos...'); // Debug
      
      const response = await fetch(`${API_URL}/api/videos`);
      console.log('Response status:', response.status); // Debug
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Dados recebidos:', data); // Debug
      
      set({ videos: data, isLoading: false });
    } catch (error) {
      console.error('Erro ao buscar vídeos:', error); // Debug
      set({ error: (error as Error).message, isLoading: false });
    }
  },
}));