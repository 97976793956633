import { useEffect } from 'react';
import { useVideoStore } from '../store/videoStore';
import { Loader2 } from 'lucide-react';

const AdminPage = () => {
  const { videos, isLoading, error, fetchVideos } = useVideoStore();

  useEffect(() => {
    console.log('AdminPage montada, iniciando fetch...'); // Debug
    fetchVideos();
  }, [fetchVideos]);

  if (error) {
    console.error('Erro na AdminPage:', error); // Debug
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="text-red-500 text-center">
          <p>Error: {error}</p>
          <button 
            type="button"
            onClick={() => fetchVideos()}
            className="mt-4 px-4 py-2 bg-red-600 rounded hover:bg-red-700"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-6">PlaybackIds in Database</h1>
        
        {isLoading ? (
          <div className="flex items-center justify-center py-8">
            <Loader2 className="animate-spin mr-2" size={24} />
            <span>Loading...</span>
          </div>
        ) : (
          <div className="space-y-4">
            {videos.length === 0 ? (
              <p className="text-gray-400">No videos found in database</p>
            ) : (
              <div className="space-y-3">
                {videos.map((video) => (
                  <div
                    key={video.playbackId}
                    className="p-4 bg-gray-800 rounded"
                  >
                    <p className="text-primary font-mono">{video.playbackId}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPage;